import {
    Row,
    Col,
    theme,
    Table,
    Divider,
    Form,
    Select,
    InputNumber,
    Button,
    Typography,
    Input,
    Space,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceInfo } from "../../store/actions/device.action";
import {
    fetchMinimumMinutes,
    updateMinimumMinutes,
} from "../../store/actions/minminutes.action";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import DropDown from "../../components/downloadtab";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import exportAsCSV from "../../services/exportToCVS.service";
import { min } from "moment";

const Threshold = () => {
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [downloadType, setDownloadType] = useState("PDF");
    const [selectedData, setSelectedData] = useState("");
    const [minminutes, setMinMins] = useState([]);
    let downloadPDF = [];
    let downloadCSV = [];
    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          setSelectedData(selectedRows)
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        },
      };
    const downloadDropdownType = [
        {
          value: "PDF",
          label: "PDF",
        },
        {
          value: "CSV",
          label: "CSV",
        },
      ];
    const downloadFile = () => {
    if (downloadType === "PDF") {
        console.log("download Type: PDF");
        //PDF converter
        downloadPDF();
    } else if (downloadType === "CSV") {
        console.log("download Type: CSV");
        //CSV converter
        downloadCSV();
    }
    };
    const searchInput = useRef(null);
    const dispatch = useDispatch();
        const isLoading = useSelector((state) => state?.signin?.isLoading);
    const companyNames = useSelector((state) => {
        const name = state.deviceInfo?.finalData
            ?.map((data) => data?.name)
            .filter((name) => !name.toLowerCase().includes("initial"));
        const sortedNames = name.sort((a, b) =>
            a.toLowerCase().localeCompare(b.toLowerCase())
        );
        return sortedNames;
    });

    const minMinutes = useSelector((state) => state?.minminutes?.minminutes );

    useEffect(() => {
        let copy = JSON.parse(JSON.stringify(minMinutes));
        let newMinMinutes = copy?.filter((e) => e.value > 0)
        newMinMinutes.forEach((data, index) => {data.key = index})
        setMinMins(
            newMinMinutes
        );
    }, [minMinutes])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    downloadPDF = () => {
        let downLoadArray = [];
        let count = 0;
        for (let list of selectedData ) {
            if (list) {
                count++;
                let newArray = [
                    count,
                    list?.name,
                    list?.value,

                ];
                downLoadArray.push(newArray);
            }
        }

        const headerList = [
            "Index",
            "Customer Name",
            "Minimum Minutes",
        ];

        const doc = new jsPDF("landscape", "px", "a4");
        doc.setFontSize(18);
        doc.text("USAGE", 30, 20);
        doc.setFontSize(12);
        autoTable(doc, {
            startY: 50,
            head: [headerList],
            body: downLoadArray,
            headStyles: {
                fillColor: [220, 220, 220],
                textColor: [33, 33, 33],
            },
        });
        doc.save(new Date().toISOString().split("T")[0]);
    };

    downloadCSV = () => {
        let count = 0;
        let downloadArray = [];
        if (!selectedData || selectedData?.name?.length == 0)
            return alert("no data", selectedData);
        else {
            for (let list of selectedData) {
                count++;
                let newList = {
                    "Index No.": count,
                    "Customer Name": list?.name,
                    "Minimum Minutes": list?.value,

                };
                downloadArray.push(newList);
            }
            exportAsCSV(
                downloadArray,
                new Date().toISOString().split("T")[0],
                [
                    "Index No.",
                    "Customer Name",
                    "Minimum Minutes",
                ],
            );
        }
    };

    useEffect(() => {
        if (!companyNames || (companyNames && companyNames.length == 0)) {
            dispatch(getDeviceInfo());
        }
    }, []);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() =>
                            clearFilters && handleReset(clearFilters)
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text.toString()
            ),
    });

    const columns = [
        {  
            title: "Company Name",
            dataIndex: "name",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps("name"),
        },
        {
            title: "Minimum Minutes",
            dataIndex: "value",
            sorter: (a, b) =>
                a.value.toString().localeCompare(b.value.toString()),
            ...getColumnSearchProps("value"),
        },
    ];

    return (
        <div
            style={{
                backgroundColor: colorBgContainer,
                padding: 10,
                borderRadius: 10,
            }}
        >
            <Row align={"middle"} >
            <Typography.Title level={3}>Threshold</Typography.Title>
            <div  style={{marginLeft:'auto'}}>
                <DropDown
                defaultValue={downloadType}
                dropdownOptions={downloadDropdownType}
                handleTypeSelect={(downloadType) => setDownloadType(downloadType)}
                />
                <Button type="primary" onClick={downloadFile}>
                Download
               </Button>
               </div>
            </Row>
            <Divider />
            <Row gutter={20}>
                <Col flex={"33.33%"}>
                    <Form
                        layout="vertical"
                        onFinish={(values) =>
                            dispatch(updateMinimumMinutes(values))
                        }
                    >
                        <Form.Item
                            label="Company Name"
                            name="blueprintName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input company name!",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Enter Company Name"
                                filterOption={(input, option) =>
                                    (option?.value ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                {companyNames?.map((companyName) => {
                                    return (
                                        <Select.Option value={companyName}>
                                            {companyName}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Minimum Minutes"
                            name="minMins"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input threshold value!",
                                },
                            ]}
                        >
                            <InputNumber
                                placeholder="Enter Minimum Minutes"
                                min={0}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                block
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                            >
                                Update
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col flex={"66.66%"}>
                    <Table
                        loading={isLoading}
                        columns={columns}
                        rowSelection={{
                            ...rowSelection,
                          }}
                        dataSource={minminutes}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default Threshold;
