import AuthService from "./auth.service";
import request from './axios';
import DeviceInfoService from "./device.information.service";
import MinMinutesService from "./minminutes.service";

class Service {
  static Auth() {
    return new AuthService(request);
  }

  static DeviceInfo() {
    return new DeviceInfoService(request);
  }

  static MinMinutes() {
    return new MinMinutesService(request);
  }
}

export default Service;
