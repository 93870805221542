import React, { useState } from "react";
import { Select } from "antd";
import "./downloadtab.scss";

const DropDown = (props) => {
  const [type, setType] = useState("");

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    console.log(value);

    setType(value);
    props.handleTypeSelect(type);
  };
  return (
    !props.hidden && <Select
      defaultValue={props.defaultValue}
      className="select-div"
      onSelect={props.handleTypeSelect}
      options={props.dropdownOptions}
    />
  );
};

export default DropDown;
