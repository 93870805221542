import { Button, Card, Col, Row, Spin, Table, Typography, theme } from "antd";
import ReactECharts from "echarts-for-react";
import "./reports.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOfflineDevices } from "../../store/actions/report.action";
import { DownloadOutlined, TableOutlined } from "@ant-design/icons";
import { GetColumnSearchProps } from "../../components/table/common";
import moment from "moment";
import exportAsCSV from "../../services/exportToCVS.service";
import * as echarts from 'echarts/core';

const OfflineDevices = () => {
    const [data, setData] = useState([]);
    const [month, setMonth] = useState();
    const {
        token: { 
            colorBgContainer, 
            colorText,
            alertColor,
            lineChartColor
        },
    } = theme.useToken();

    const dispatch = useDispatch()

    const state = useSelector((s) => s?.report?.offline);
    const isLoading = useSelector((s) => s.signin?.isLoading);

    useEffect(() => {
        !state?.offline?.chartData?.length && dispatch(fetchOfflineDevices())
    }, []);

    const getOption = () => {
        let graphics = echarts.graphic;
        return {
            backgroundColor: colorBgContainer,
            tooltip: {
                trigger: "axis",
                position: function (pt) {
                    return [pt[0], "10%"];
                },
                axisPointer: {
                    type: "shadow",
                }
            },
            grid: {
                top: 20,
                left: 10,
                right: 10,
                bottom: 65,
                containLabel: true,
            },
            xAxis: [
                {
                    type: "category",
                    data: state?.chartData?.legends,
                    axisTick: {
                        alignWithLabel: true,
                    },
                    triggerEvent: true,
                },
            ],
            yAxis: [
                {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                    },
                },
            ],
            dataZoom: [
                {
                    type: "inside",
                    start: 0,
                    end: 60,
                },
                {
                    start: 0,
                    end: 60,
                },
            ],
            series: [
                {
                    name: "Offline Devices",
                    type: "bar",
                    barWidth: "50%",
                    data: state?.chartData?.seriesData,
                },
                {
                    type: "line",
                    barWidth: "50%",
                    data: state?.chartData?.seriesData,
                    tooltip: {
                        show: false
                    },
                    lineStyle: {color: new graphics.LinearGradient(
                        0, 0, 0.5, 1,
                        [{offset: 0, color: 'yellow'}, {offset: 1, color: 'red'}]
                    )}
                },
            ],
        };
    };

    const handleChartClick = (params) => {
        let data = [];
        let group = state?.finalData?.find((e) => e?.date == params.name);
        group.data.forEach((e) => {
            data = data.concat(e.body);
        })
        setMonth(params.name);
        setData(data);
    }

    const onEvents = {
        'click': handleChartClick,
    }

    const downloadCSV = () => {
        let count = 0;
        let downloadArray = [];
            for (let list of data) {
                count++;
                let newList = {
                    "Index No.": count,
                    "Deivce Name": list.deviceName,
                    "Blueprint Name": list.blueprintName,
                    "Serial Number": list.serialNumber,
                    "Last Checked In": moment(list.lastCheckIn).format(
                        "YYYY/MM/DD HH:MM"
                    ),
                    "Number of Days": list.offlineDays
                };
                downloadArray.push(newList);
            }
            exportAsCSV(
                downloadArray,
                new Date().toISOString().split("T")[0],
                [
                    "Index No.",
                    "Deivce Name",
                    "Blueprint Name",
                    "Serial Number",
                    "Last Checked In",
                    "Number of Days"
                ],
                `${month} Offline Devices`,
                `Device Report (Offline Devices - ${month})`
            );
    };

    const columns = [
        {
            title: "Device Name",
            dataIndex: "deviceName",
            sorter: (a, b) => a.blueprintName.localeCompare(b.blueprintName),
            ...GetColumnSearchProps({dataIndex: "deviceName"})
        },
        {
            title: "Blueprint Name",
            dataIndex: "blueprintName",
            sorter: (a, b) => a.blueprintName.localeCompare(b.blueprintName),
            ...GetColumnSearchProps({dataIndex: "blueprintName"})
        },
        {
            title: "Serial Number",
            dataIndex: "serialNumber",
            sorter: (a, b) => a.serialNumber.localeCompare(b.serialNumber),
            ...GetColumnSearchProps({dataIndex: "serialNumber"})
        },
        {
            title: "Last Checked In",
            dataIndex: "lastCheckIn",
            sorter: (a, b) =>
                moment(a.lastCheckIn) - moment(b.lastCheckIn),
            render: (date) => moment(date).format('MM/DD/YYYY')
            // ...GetColumnSearchProps({dataIndex: "lastCheckIn", isDate: true}),
        },
        {
            title: "Number of Days",
            dataIndex: "offlineDays",
            sorter: (a, b) => a.offlineDays - b.offlineDays,
        },
    ];

    return (
        <Spin spinning={isLoading}>
            <Typography.Title level={3}>
                Offline Devices
            </Typography.Title>
            <Card className="bar-devices-card" bordered={false} >
                <ReactECharts 
                    option={getOption()} 
                    style={{height: '100%'}}
                    onEvents={onEvents}
                />
            </Card>
            <br/>
            <Table
                dataSource={data}
                className="device-table"
                columns={columns}
                title={() => 
                    <Row align={"middle"}>
                        <Col flex={"auto"}>
                        <b>&nbsp;&nbsp;< TableOutlined style={{color: alertColor}} /> Devices {month && `- ${month}`}</b>
                        </Col>
                        <Col flex={"50px"}>
                            <Button 
                                disabled={!month} 
                                icon={<DownloadOutlined/>}
                                onClick={downloadCSV}
                            >CSV</Button>
                        </Col>
                    </Row>
                }
                size="middle"
                footer={() => <i>&nbsp; <span style={{color: alertColor}}>Note:</span> Click on the month in above bar chart to view data.</i>}
            />
        </Spin>
    )
}

export default OfflineDevices;