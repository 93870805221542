import ActionType from '../action.types';
import Service from '../../services';
import * as echarts from 'echarts/core';
import { saveMinimumMinutes } from './minminutes.action';
import moment from 'moment';

export function saveOfflineDevices(response) {
    let graphics = echarts.graphic;
    const colors = [
        "#D3649F",
        "blue",
        "#ff0256",
        "#6495ed",
        "crimson",
        "#cb11b8",
        "#11a3cb",
        "#80c455",
        "#e2570e",
        "#00d68f",
    ];
    let finalData = [];
    for (let i = 0; i < Array.from({length: 12}).length; i++) {
        let mdmData = response[i]?.response;
        let monthData = {
            date: moment().startOf("year").add("month", i).format("MMMM"),
            totalOfflineDevices: 0,
            data: []
        }
        if (!mdmData) {
            finalData.push(monthData);
            continue;
        }
        for (let index = 0; index < mdmData.keys.length; index++) {
            const keyName = mdmData.keys[index]
            const element = mdmData.response[keyName];
            const obj = {name:keyName, body: element, total: element.length}
            monthData.totalOfflineDevices += element.length;
            monthData.data.push({...obj});
        }

        finalData.push(monthData);
    }

    let chartData = {
        legends: finalData.map((data) => data?.date),
        seriesData: finalData.map((data, idx) => ({
            value: data?.totalOfflineDevices,
            itemStyle: {
                color: colors[idx % colors.length]
            },
        }))
    }

    console.log(finalData, chartData)

    return {
        type: ActionType.OFFLINE_DEVICES,
        offline: {
            chartData,
            finalData
        }
    };
}

export function fetchOfflineDevices() {
    return async (dispatch) => {
        try {
            let response = await Service.DeviceInfo().getOfflineDevices();
            dispatch(saveOfflineDevices(response));
        } catch(err) {
            console.log(err);
        }
    };
}
