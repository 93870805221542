import ActionType from "../action.types";

/*
    Reducer function to push data to the store in case of different signin action types
*/

export default function signin(
    state = {
        isLoading: false,
        token: null,
        sessionId: null,
        user: {},
        theme: "light",
        selectedCompanies: undefined
    },
    action
) {
    switch (action.type) {
        case ActionType.LOGIN_ERROR:
            return {
                ...state,
                error: action.hasError,
                message: action.message,
                isLoading: action.isLoading,
            };
        case ActionType.OPERATION_INPROGRESS:
            return { ...state, isLoading: action.isLoading };
        case ActionType.LOGIN_SUCCESS:
            return {
                ...state,
                token: action.token,
                sessionId: action.sessionId,
                isLoading: action.isLoading,
                user: action.user,
                selectedCompanies: action.selectedCompanies
            };
        case ActionType.CHANGE_THEME:
            return { ...state, theme: action.theme };
        case ActionType.UPDATE_USER:
            return { ...state, user: { ...state.user, ...action.user } };
        case ActionType.LOGOUT:
            return {
                ...state,
                token: action.token,
                isLoading: action.isLoading,
                user: action.user,
                theme: "light",
            };
        default:
            return state;
    }
}
