import { combineReducers } from 'redux';
import signin from './signin.reducer';
import deviceInfo from './device.info.reducer';
import minminutes from './minminutes.reducer';
import report from './report.reducer';

// import reducers and add them to the below object.
export default combineReducers({
    signin,
    deviceInfo,
    minminutes,
    report
});
