import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import {
    BarChartOutlined,
  FileOutlined,
  PieChartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Divider, Layout, Menu, Row, theme } from "antd";
import Logo from '../../images/Logo-voyce.png';
import SmallLogo from '../../images/logo.png';
import LogoWhite from '../../images/VOY-Logo-WHITE.png';
import { useSelector } from "react-redux";

const { Sider } = Layout;

const SideMenu = ({collapsed, setCollapsed}) => {

    const location = useLocation();
    const [path, setPath] = useState(location.pathname);
    const currentTheme = useSelector((state) => state.signin?.theme == 'dark' ? "light" : "dark")
  
    useEffect(() => {
      setPath(location.pathname);
      console.log(location.pathname);
    }, [location.pathname]);

    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }
      
    const items = [
        getItem(<Link to="/pages">Dashboard</Link>, "/pages", <PieChartOutlined />),
        getItem("Management", "sub1", <UserOutlined />, [
        getItem(<Link to="/pages/blueprint">Blueprint</Link>, "/pages/blueprint"),
        getItem(<Link to="/pages/usage">Usage</Link>, "/pages/usage"),
        ]),
        getItem("Configuration", "sub2", <FileOutlined />, [
        getItem(<Link to="/pages/threshold">Threshold</Link>, "/pages/threshold"),
        ]),
        getItem("Reports", "/pages/reports", <BarChartOutlined />, [
            getItem(<Link to="/pages/reports/offline-devices">Offline Devices</Link>, "/pages/reports/offline-devices"),
        ]),
    ];

    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            className="sider-container"
            theme={currentTheme}
        >
            {
                !collapsed ? 
                <div style={{margin: 16, height: 26}}>
                    <Row justify={"center"} align={"middle"}>
                        <img style={{ marginTop: 7}} height={18} src={LogoWhite} />
                    </Row>
                </div> : 
                <div style={{margin: 16, height: 26}}>
                    <Row justify={"center"} align={"middle"}>
                        <img style={{filter: 'drop-shadow(0 0 0.75rem white)'}} height={26} src={SmallLogo} />
                    </Row>
                </div>
            }
            <Divider/>
            <Menu
                selectedKeys={[path]}
                theme={currentTheme}
                defaultSelectedKeys={[path]}
                mode="inline"
                items={items}
            />
      </Sider>
    )
}

export default SideMenu;