const development = {
    API_BASE_URL: "http://localhost:3000",
    AUTH_BASE_URL: "http://localhost:3001",
    BASE_URL: "http://localhost:4200",
    S3_BUCKETNAME: "vmds-prod",
};

const production = {
    API_BASE_URL: "https://vdmsapi.voyceglobal.com",
    AUTH_BASE_URL: "https://vdmsauth.voyceglobal.com",
    BASE_URL: "https://vdms.voyceglobal.com",
    S3_BUCKETNAME: "vmds-prod",
};

export default process.env.NODE_ENV === "development"
    ? development
    : production;
