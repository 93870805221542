import { useParams, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Table, Row, Button, Typography } from "antd";
import "jspdf-autotable";
import { useSelector } from "react-redux";
import { GetColumnSearchProps } from "../../components/table/common";
import { Link } from "react-router-dom";
import moment from "moment";
import DropDown from "../../components/downloadtab";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import exportAsCSV from "../../services/exportToCVS.service";

const HomeTable = () => {
    let columns = [];
    let downloadPDF = [];
    let downloadCSV = []
    const { id } = useParams();
    const navigate = useNavigate();
    const routeChange = () => {
        let path = `/pages`;
        navigate(path);
    };
    const isLoading = useSelector((state) => state?.signin?.isLoading);
    const [deviceFilter, setDeviceFilter] = useState([]);
    const [downloadType, setDownloadType] = useState("PDF");
    const downloadDropdownType = [
        {
          value: "PDF",
          label: "PDF",
        },
        {
          value: "CSV",
          label: "CSV",
        },
      ];

    const data = useSelector((state) => {
        let deviceInfo = state.deviceInfo;
        switch (id) {
            case "inventory":
                return deviceInfo?.totalUnsedDevices;
            case "initial-setup":
                return deviceInfo?.totalDeviceDeployed;
            case "deployed-devices":
                return deviceInfo?.totalInUseDevices;
            case "total-customers":
                return deviceInfo?.finalData?.filter(
                    (data) =>
                        !data?.name.toLowerCase().includes("initial_setup")
                );
            default:
        }
    });

    const downloadFile = () => {
        if (downloadType === "PDF") {
          console.log("download Type: PDF");
          //PDF converter
          downloadPDF();
        } else if (downloadType === "CSV") {
          console.log("download Type: CSV");
          //CSV converter
          downloadCSV();
        }
      };


    
    if (id === "total-customers") {
        downloadPDF = () => {
            let downLoadArray = [];
            let count = 0;
            for (let list of data) {
                if (list) {
                    count++;
                    let newArray = [
                        count,
                        list?.name,
                        list?.total,
   
                    ];
                    downLoadArray.push(newArray);
                }
            }
    
            const headerList = [
                "Index",
                "Customer Name",
                "No of Devices",
            ];
    
            const doc = new jsPDF("landscape", "px", "a4");
            doc.setFontSize(18);
            doc.text(id + " Blueprint", 30, 20);
            doc.setFontSize(12);
            autoTable(doc, {
                startY: 50,
                head: [headerList],
                body: downLoadArray,
                headStyles: {
                    fillColor: [220, 220, 220],
                    textColor: [33, 33, 33],
                },
            });
            doc.save(new Date().toISOString().split("T")[0]);
        };
    
        downloadCSV = () => {
            let count = 0;
            let downloadArray = [];
            if (!data || data?.name?.length == 0)
                return alert("no data", data);
            else {
                for (let list of data) {
                    count++;
                    let newList = {
                        "Index No.": count,
                        "Customer Name": list?.name,
                        "No of Devices": list?.total,

                    };
                    downloadArray.push(newList);
                }
                exportAsCSV(
                    downloadArray,
                    new Date().toISOString().split("T")[0],
                    [
                        "Index No.",
                        "Customer Name",
                        "No of Devices",
                    ],
                );
            }
        };
        columns = [
            {
                title: "Customer Name",
                dataIndex: "name",
                defaultSortOrder: "descend",
                sorter: (a, b) => a.name.localeCompare(b.name),
                ...GetColumnSearchProps({dataIndex: "name"}),
                render: (name) => {
                    return (
                        <Link to={`/pages/blueprint/${name}?from=/pages/dashboard/total-customers`}>{name} </Link>
                    );
                  }
            },
            {
                title: "Number of Devices",
                dataIndex: "total",
                defaultSortOrder: "descend",
                sorter: (a, b) => a.total - b.total,
                ...GetColumnSearchProps({dataIndex: "total"}),
            },
        ];
    } else {
        downloadPDF = (range) => {
            let downLoadArray = [];
            let count = 0;
            for (let list of data) {
                if (list) {
                    count++;
                    let newArray = [
                        count,
                        list.blueprintName,
                        list.deviceName,
                        moment(list.firstEnrollment).format("YYYY/MM/DD HH:MM"),
                        moment(list.lastEnrollment).format("YYYY/MM/DD HH:MM"),
                        list.serialNumber,
                        list.osVersion,
                        list.model,
                    ];
                    downLoadArray.push(newArray);
                }
            }
    
            const headerList = [
                "Index",
                "Blueprint Name",
                "Deivce Name",
                "First Enrollment",
                "Last Enrollment",
                "Serial Number",
                "IOS Version",
                "Model",
                //   "Department",
                //   "Facility",
            ];
    
            const doc = new jsPDF("landscape", "px", "a4");
            autoTable(doc, {
                startY: 50,
                head: [headerList],
                body: downLoadArray,
                headStyles: {
                    fillColor: [220, 220, 220],
                    textColor: [33, 33, 33],
                },
            });
            doc.save(new Date().toISOString().split("T")[0]);
        };
    
        downloadCSV = (range) => {
            let count = 0;
            let downloadArray = [];
            if (!data || data.length == 0)
                return alert("no data", data);
            else {
                for (let list of data) {
                    count++;
                    let newList = {
                        "Index No.": count,
                        "Blueprint Name": list.blueprintName,
                        "Deivce Name": list.deviceName,
                        "First Enrollment": moment(list.firstEnrollment).format(
                            "YYYY/MM/DD HH:MM"
                        ),
                        "Last Enrollment": moment(list.lastEnrollment).format(
                            "YYYY/MM/DD HH:MM"
                        ),
                        "Serial Number": list.serialNumber,
                        "IOS Version": list.osVersion,
                        "Model No.": list.model,
                    };
                    downloadArray.push(newList);
                }
                exportAsCSV(
                    downloadArray,
                    new Date().toISOString().split("T")[0],
                    [
                        "Index No.",
                        "Blueprint Name",
                        "Deivce Name",
                        "First Enrollment",
                        "Last Enrollment",
                        "Serial Number",
                        "IOS Version",
                        "Model No.",
                    ],
                );
            }
        };
        columns = [
            {
                title: "Blueprint Name",
                dataIndex: "blueprintName",
                defaultSortOrder: "descend",
                sorter: (a, b) =>
                    a.blueprintName.localeCompare(b.blueprintName),
                ...GetColumnSearchProps({dataIndex: "blueprintName"}),
            },
            {
                title: "Device Name",
                dataIndex: "deviceName",
                defaultSortOrder: "descend",
                sorter: (a, b) => a.deviceName.localeCompare(b.deviceName),
                ...GetColumnSearchProps({dataIndex: "deviceName"}),
            },
            {
                title: "First Enrollment",
                dataIndex: "firstEnrollment",
                sorter: (a, b) =>
                    moment(a.firstEnrollment) - moment(b.firstEnrollment),
                ...GetColumnSearchProps({dataIndex: "firstEnrollment", isDate: true}),
            },
            {
                title: "Last Enrollment",
                dataIndex: "lastEnrollment",
                sorter: (a, b) =>
                    moment(a.lastEnrollment) - moment(b.lastEnrollment),
                ...GetColumnSearchProps({ dataIndex: "lastEnrollment", isDate: true}),
            },
            {
                title: "Serial Number",
                dataIndex: "serialNumber",
                sorter: (a, b) => a.serialNumber.localeCompare(b.serialNumber),
                ...GetColumnSearchProps({dataIndex: "serialNumber"}),
            },
            {
                title: "IOS Version",
                dataIndex: "osVersion",
                sorter: (a, b) => a.osVersion.localeCompare(b.osVersion),
            },
            {
                title: "Model",
                dataIndex: "model",
                sorter: (a, b) => a.model.localeCompare(b.model),
            },
        ];
    }
    return (
        <>
            <Row align={"middle"}>
                {
                    <Button type="primary" onClick={routeChange}>
                        ←
                    </Button>
                }
                <Typography.Text level={4}>
                    <span className="title-text">{id.toUpperCase()}</span>
                </Typography.Text>
                <div  style = {{marginLeft: 'auto'}}>
                <DropDown
                defaultValue={downloadType}
                dropdownOptions={downloadDropdownType}
                handleTypeSelect={(downloadType) => setDownloadType(downloadType)}
                />
                <Button type="primary" onClick={downloadFile}>
                Download
               </Button>
               </div>
            </Row>
            <br/>
            <Table loading={isLoading} columns={columns} dataSource={data} 
              />
        </>
    );
};

export default HomeTable;