import { useParams, Link } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Table, InputRef, Input, Space, Button, DatePicker, Typography, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import DropDown from "../downloadtab";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import "./table.scss";
import { useSelector } from "react-redux";
import exportAsCSV from "../../services/exportToCVS.service";
import PropType from 'prop-types';

const { RangePicker } = DatePicker;

const downloadDropdownType = [
  {
    value: "PDF",
    label: "PDF",
  },
  {
    value: "CSV",
    label: "CSV",
  },
];

const timeRangeDropdownType = [
  {
    value: "Yesterday",
    label: "Yesterday",
  },
  {
    value: "This Week",
    label: "This Week",
  },
  {
    value: "Last Week",
    label: "Last Week",
  },
  {
    value: "This Month",
    label: "This Month",
  },
  {
    value: "Last Month",
    label: "Last Month",
  },
  {
    value: "This Year",
    label: "This Year",
  },
  {
    value: "Last Year",
    label: "Last Year",
  },
  {
    value: "Custom Date",
    label: "Custom Date",
  },
];

const monthRangeDropdownType = [
  {
    value: moment("2022").set("month", 9).startOf("month").toString(),
    label: "October 2022",
  },
  {
    value: moment("2022").set("month", 10).startOf("month").toString(),
    label: "November 2022",
  },
  {
    value: moment("2022").set("month", 11).startOf("month").toString(),
    label: "December 2022",
  },
  ...Array.from({length: 12}).map((value, i) => ({
    value: moment().startOf('year').add(i, 'months').toString(),
    label: moment().startOf('year').add(i, 'months').add(1, "days").format("MMMM YYYY")
  })).filter((month) => moment(month.value).isSameOrBefore(moment(), "month"))
]

const SmartTable = (props) => {
  const [downloadType, setDownloadType] = useState("PDF");
  const [timeRange, setTimeRageType] = useState(props.rangeType == "month" ? moment().format("MMMM YYYY") : "Last Month");
  const [range, setRange] = useState(props.rangeType == "month" ? {
    minDate: moment().startOf('month').toISOString(),
    maxDate: moment().endOf('month').toISOString()
  } : 
  {
    maxDate: moment().subtract(1, "months").endOf("month").toDate(),
    minDate: moment().subtract(1, "months").startOf("month").toDate(),
  });
  const [customDateDialog, setCustomDateDialog] = useState(false);

  useEffect(() => {
    console.log("~~~~RANGE CHANGED: ", range);
    props.dateFilter(range);
  }, [range]);

  const isLoading = useSelector((state) => state?.signin?.isLoading);

  const handleCustomDateDialog = () => {
    setCustomDateDialog(false);
    console.log("customDateDialog", customDateDialog);
  };

  const handleTimeRage = (value) => {
    console.log(`this time range is: ${value}`);
    setTimeRageType(value);
    if (value === "Custom Date") {
      setCustomDateDialog(true);
    } else if (value === "Today") {
      setRange((prevState) => ({
        ...prevState,
        maxDate: moment().endOf("day").toDate(),
        minDate: moment().startOf("day").toDate(),
      }));
    } else if (value === "Yesterday") {
      setRange((prevState) => ({
        ...prevState,
        maxDate: moment().subtract(1, "day").endOf("day").toDate(),
        minDate: moment().subtract(1, "day").startOf("day").toDate(),
      }));
    } else if (value === "This Week") {
      setRange((prevState) => ({
        ...prevState,
        maxDate: moment().endOf("isoWeek").toDate(),
        minDate: moment().startOf("isoWeek").toDate(),
      }));
    } else if (value === "Last Week") {
      setRange((prevState) => ({
        ...prevState,
        maxDate: moment().subtract(1, "weeks").endOf("isoWeek").toDate(),
        minDate: moment().subtract(1, "weeks").startOf("isoWeek").toDate(),
      }));
    } else if (value === "This Month") {
      setRange((prevState) => ({
        ...prevState,
        maxDate: moment().endOf("month").toDate(),
        minDate: moment().startOf("month").toDate(),
      }));
    } else if (value === "Last Month") {
      setRange((prevState) => ({
        ...prevState,
        maxDate: moment().subtract(1, "months").endOf("month").toDate(),
        minDate: moment().subtract(1, "months").startOf("month").toDate(),
      }));
    } else if (value === "This Year") {
      setRange((prevState) => ({
        // ...prevState,
        maxDate: moment().endOf("year").toDate(),
        minDate: moment().startOf("year").toDate(),
      }));
    } else if (value === "Last Year") {
      setRange((prevState) => ({
        // ...prevState,
        maxDate: moment().subtract(1, "years").endOf("year").toDate(),
        minDate: moment().subtract(1, "years").startOf("year").toDate(),
      }));
    } else {
      setRange((prevState) => ({
        ...prevState,
        minDate: moment(value).startOf('month').toISOString(),
        maxDate: moment(value).endOf('month').toISOString()
      }));
    }
  };
  
  const downloadFile = () => {
    if (downloadType === "PDF") {
      console.log("download Type: PDF");
      //PDF converter
      props?.downloadPDF(range);
    } else if (downloadType === "CSV") {
      console.log("download Type: CSV");
      //CSV converter
      props?.downloadCSV(range);
    }
  };

  return (
    <>
      <Row align={"middle"} >
        {!props?.hideBackBtn && <Button type="primary" onClick={props.handleBackClick} >←</Button>}
        <Typography.Text level={4}><span className="title-text">{props.title}</span></Typography.Text>
      </Row>

      <div className="download-div">
        {!customDateDialog ? (
          <DropDown
            hidden={props.disableFilter} 
            defaultValue={timeRange}
            dropdownOptions={props.rangeType == "month" ? monthRangeDropdownType : timeRangeDropdownType}
            handleTypeSelect={(timeRange) => handleTimeRage(timeRange)}
          />
        ) : (
          <div hidden={props.disableFilter} className="range-picker-div">
            <RangePicker
              onChange={(x) => {
                console.log(x);
                setRange((prevState) => ({
                  ...prevState,
                  maxDate: x[1].$d,
                  minDate: x[0].$d,
                }));
              }}
            />
            <Button
              className="done-btn-div"
              type="primary"
              onClick={handleCustomDateDialog}
            >
              Done
            </Button>
          </div>
        )}

        <DropDown
          defaultValue={downloadType}
          dropdownOptions={downloadDropdownType}
          handleTypeSelect={(downloadType) => setDownloadType(downloadType)}
        />
        <Button type="primary" onClick={downloadFile}>
          Download
        </Button>
      </div>

      <Typography.Text>
      <div hidden={props.disableFilter} className={props.hideBackBtn ? "date-text-div-no-back" : "date-text-div"}>
        Selected Time: {moment(range.minDate).format("MM/DD/YYYY")} -{" "}
        {moment(range.maxDate).format("MM/DD/YYYY")}
      </div>
      </Typography.Text>
      <Table loading={isLoading} columns={props.columns} dataSource={props.data} />
    </>
  );
};

SmartTable.propTypes = {
  downloadPDF: PropType.func.isRequired,
  downloadCSV: PropType.func.isRequired,
  handleBackClick: PropType.func.isRequired,
  columns: PropType.array.isRequired,
  title: PropType.string,
  data: PropType.array.isRequired,
  hideBackBtn: PropType.bool,
  rangeType: PropType.oneOf(["month", "type"]),
  disableFilter: PropType.bool
}

export default SmartTable;
