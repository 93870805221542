import SmartTable from "../../components/table";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { useSelector } from "react-redux";
import exportAsCSV from "../../services/exportToCVS.service";
import { GetColumnSearchProps } from "../../components/table/common";

const BlueprintTable = () => {
    const location = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [deviceFilter, setDeviceFilter] = useState([]);

    const data = useSelector((state) => state?.deviceInfo?.finalData);

    useEffect(() => {dateFilter()}, [])

    const dateFilter = (range) => {
        const filteredData = data
            ?.find((company) => company.name == id)
            ?.body
            // ?.filter((item) => {
            //     //   console.log("what is item : ", item);
            //     const itemDate = new Date(item.firstEnrollment);
            //     console.log("what is itemDate : ", itemDate);
            //     JSON.stringify(range);
            //     console.log("what is range : ", range);

            //     return itemDate >= range.minDate && itemDate <= range.maxDate;
            // })
        filteredData?.forEach(element => {
            element.mac_address = element?.details?.network?.mac_address
        });
        setDeviceFilter(filteredData);
    };

    const downloadPDF = (range) => {
        let downLoadArray = [];
        let count = 0;
        for (let list of deviceFilter) {
            if (list) {
                count++;
                let newArray = [
                    count,
                    list.blueprintName,
                    list.deviceName,
                    moment(list.firstEnrollment).format("YYYY/MM/DD HH:MM"),
                    moment(list.lastEnrollment).format("YYYY/MM/DD HH:MM"),
                    list.serialNumber,
                    list.mac_address,
                    list.osVersion,
                    list.model,
                ];
                downLoadArray.push(newArray);
            }
        }

        const headerList = [
            "Index",
            "Blueprint Name",
            "Deivce Name",
            "First Enrollment",
            "Last Enrollment",
            "Serial Number",
            "Mac Address",
            "IOS Version",
            "Model",
            //   "Department",
            //   "Facility",
        ];

        const doc = new jsPDF("landscape", "px", "a4");
        doc.setFontSize(18);
        doc.text(id + " Blueprint", 30, 20);
        doc.setFontSize(12);
        doc.text(
            "Selected range: " +
                moment(range.minDate).format("MMM DD YY").toString() +
                " - " +
                moment(range.maxDate).format("MMM DD YY").toString(),
            30,
            35
        );
        autoTable(doc, {
            startY: 50,
            head: [headerList],
            body: downLoadArray,
            headStyles: {
                fillColor: [220, 220, 220],
                textColor: [33, 33, 33],
            },
        });
        doc.save(new Date().toISOString().split("T")[0]);
    };

    const downloadCSV = (range) => {
        let count = 0;
        let downloadArray = [];
        if (!deviceFilter || deviceFilter.length == 0)
            return alert("no data", deviceFilter);
        else {
            for (let list of deviceFilter) {
                count++;
                let newList = {
                    "Index No.": count,
                    "Blueprint Name": list.blueprintName,
                    "Deivce Name": list.deviceName,
                    "First Enrollment": moment(list.firstEnrollment).format(
                        "YYYY/MM/DD HH:MM"
                    ),
                    "Last Enrollment": moment(list.lastEnrollment).format(
                        "YYYY/MM/DD HH:MM"
                    ),
                    "Serial Number": list.serialNumber,
                    "Mac Address": list.mac_address,
                    "IOS Version": list.osVersion,
                    "Model No.": list.model,
                };
                downloadArray.push(newList);
            }
            exportAsCSV(
                downloadArray,
                new Date().toISOString().split("T")[0],
                [
                    "Index No.",
                    "Blueprint Name",
                    "Deivce Name",
                    "First Enrollment",
                    "Last Enrollment",
                    "Serial Number",
                    "Mac Address",
                    "IOS Version",
                    "Model No.",
                ],
                `${id} Blueprint`,
                "Device Information (Selected Range: " +
                    moment(range.minDate).format("MMM DD YY").toString() +
                    " - " +
                    moment(range.maxDate).format("MMM DD YY").toString() +
                    ")"
            );
        }
    };

    const columns = [
        {
            title: "Blueprint Name",
            render: () => id,
        },
        {
            title: "Device Name",
            dataIndex: "deviceName",
            defaultSortOrder: "descend",
            sorter: (a, b) => a?.deviceName?.localeCompare(b?.deviceName),
            ...GetColumnSearchProps({dataIndex: "deviceName"}),
        },
        {
            title: "First Enrollment",
            dataIndex: "firstEnrollment",
            sorter: (a, b) =>
                moment(a.firstEnrollment) - moment(b.firstEnrollment),
            ...GetColumnSearchProps({dataIndex: "firstEnrollment", isDate: true}),
        },
        {
            title: "Last Enrollment",
            dataIndex: "lastEnrollment",
            sorter: (a, b) =>
                moment(a.lastEnrollment) - moment(b.lastEnrollment),
            ...GetColumnSearchProps({dataIndex: "lastEnrollment", isDate: true}),
        },
        {
            title: "Serial Number",
            dataIndex: "serialNumber",
            sorter: (a, b) => a.serialNumber.localeCompare(b.serialNumber),
            ...GetColumnSearchProps({dataIndex: "serialNumber"}),
        },
        {
            title: "Mac Address",
            dataIndex: "mac_address",
            // ...GetColumnSearchProps({dataIndex: "mac_address"}),
        },
        {
            title: "IOS Version",
            dataIndex: "osVersion",
            sorter: (a, b) => a.osVersion.localeCompare(b.osVersion),
        },
        {
            title: "Model",
            dataIndex: "model",
            sorter: (a, b) => a.model.localeCompare(b.model),
        },
    ];

    return (
        <SmartTable
            downloadPDF={downloadPDF}
            downloadCSV={downloadCSV}
            handleBackClick={() => navigate(new URLSearchParams(location.search).get("from")?new URLSearchParams(location.search).get("from"):"/pages/blueprint")}
            columns={columns}
            title={id}
            data={deviceFilter}
            dateFilter={dateFilter}
            disableFilter
        />
    );
};

export default BlueprintTable;