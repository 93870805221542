const ActionType = {
    // Login action types
    LOGIN_ERROR: "LOGIN_ERROR",
    OPERATION_INPROGRESS: "OPERATION_INPROGRESS",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGOUT: "LOGOUT",
    CHANGE_THEME: "CHANGE_THEME",
    UPDATE_USER: "UPDATE_USER",

    // DEVICES ACTION
    DEVICE_INFO: "DEVICE_INFO",
    SAVE_BLUEPRINTS: "SAVE_BLUEPRINTS",
    DEVICES_BY_DATE: "DEVICES_BY_DATE",

    //Minminutes Actions

    SAVE_MINMINUTES: 'SAVE_MINMINUTES',

    //REPORT Actions 
    OFFLINE_DEVICES: 'OFFLINE_DEVICES'
};

export default ActionType;
