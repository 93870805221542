import React, { useEffect, useState } from "react";
import { Card, Row, Col, Input, Spin, theme, Typography } from "antd";
import "./home.scss";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceInfo } from "../../store/actions/device.action";
import ReactECharts from "echarts-for-react";
import { Link } from "react-router-dom";

const Home = () => {
    const dispatch = useDispatch();
    const {
        totalUnsedDevices,
        totalCustomers,
        totalDeviceDeployed,
        totalInUseDevices,
        chartData,
    } = useSelector((state) => state.deviceInfo);
    const isLoading = useSelector((state) => state.signin?.isLoading);
    const colors = [
        "#D3649F",
        "blue",
        "#ff0256",
        "#6495ed",
        "crimson",
        "#cb11b8",
        "#11a3cb",
        "#80c455",
        "#e2570e",
        "#00d68f",
    ];

    const {
        token: { colorBgContainer, colorText },
    } = theme.useToken();

    useEffect(() => {
        if (!chartData) {
            dispatch(getDeviceInfo());
        }
    }, []);

     const seriesData = chartData?.seriesData.map((val, idx) => {
        return {
            value: val,
            itemStyle: {
                color: colors[idx % colors.length],
            },
        };
    });

    const getOption = () => {
        return {
            // backgroundColor: echarts.bg,
            tooltip: {
                trigger: "axis",
                position: function (pt) {
                    return [pt[0], "10%"];
                },
                axisPointer: {
                    type: "shadow",
                },
            },
            grid: {
                top: 20,
                left: 10,
                right: 10,
                bottom: "15%",
                containLabel: true,
            },
            xAxis: [
                {
                    type: "category",
                    data: chartData?.legends,
                    axisTick: {
                        alignWithLabel: true,
                    },
                    // axisLine: {
                    //   lineStyle: {
                    //     color: echarts.axisLineColor,
                    //   },
                    // },
                    axisLabel: {
                        // textStyle: {
                        //   color: echarts.textColor,
                        // },
                    },
                },
            ],
            yAxis: [
                {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                    },
                },
            ],
            dataZoom: [
                {
                    type: "inside",
                    start: 0,
                    end: 10,
                },
                {
                    start: 0,
                    end: 10,
                },
            ],
            series: [
                {
                    name: "Devices",
                    type: "bar",
                    barWidth: "50%",
                    //   data: chartData?.seriesData,
                    data: seriesData,
                },
            ],
        };
    };

  return (
    <Spin spinning={isLoading} tip="Cooking up your data...">
      <div className="site-card-wrapper">
        <div>
            <Typography.Title level={3} >Device Status</Typography.Title>
        </div>
      <div className="site-card-wrapper">
        <Row gutter={16} bordered={true}>
          <Col span={6}>
          <Link to={`/pages/dashboard/inventory`}>
            <Card title="Inventory" bordered={false}>
              {totalUnsedDevices?.length}
            </Card>
          </Link>
          </Col>
          <Col span={6}>
          <Link to={`/pages/dashboard/initial-setup`}>
            <Card title="Total Device Initial Setup" bordered={false}>
              {totalDeviceDeployed?.length}
            </Card>
            </Link>
          </Col>
          <Col span={6}>
          <Link to={`/pages/dashboard/deployed-devices`}>
            <Card title="Total Deployed Devices" bordered={false}>
              {totalInUseDevices?.length}
            </Card>
          </Link>
          </Col>
          <Col span={6}>
          <Link to={`/pages/dashboard/total-customers`}>
            <Card title="Total Customers" bordered={false}>
              {totalCustomers?.length}
            </Card>
          </Link>
          </Col>
        </Row>

      </div>
      <div>
      <Typography.Title level={3} >Devices Per Company</Typography.Title>
      </div>
      <Card className="bar-card" bordered={false} >

        { 
          chartData && 
          <ReactECharts option={getOption()} style={{height: '100%'}} />
        }
      </Card>
      </div>
    </Spin>
  );  

}

export default Home;
