import { message } from "antd";
import Service from "../../services";
import ActionType from "../action.types";
import { store } from "../..";

export function saveMinimumMinutes(mdmData) {
    let minminutes = mdmData.minMinsPerKey
        .map((e) => ({name: (Object.keys(e)[0]), value: e[(Object.keys(e)[0])]}));
    return {
        type: ActionType.SAVE_MINMINUTES,
        minminutes
    }
}

export function updateMinimumMinutesState({blueprintName, minMins}) {
    let minmins = store.getState()?.minminutes?.minminutes;
    let minminutes = JSON.parse(JSON.stringify(minmins));
    let index = minminutes?.findIndex((e) => e?.name == blueprintName);
    if (index != -1) minminutes[index].value = minMins;
    return {
        type: ActionType.SAVE_MINMINUTES,
        minminutes
    }
}

export function fetchMinimumMinutes() {
    return async (dispatch) => {
        try {
            let minminutes = await Service.MinMinutes().getMinimumMinutes();
            dispatch(saveMinimumMinutes(minminutes));
        } catch(err) {
            console.log(err);
        }
    };
}

export function updateMinimumMinutes({blueprintName, minMins}) {
    return async (dispatch) => {
        try {
            blueprintName = blueprintName.replace(/_/g, " ");
            let response = Service.MinMinutes().updateMinimumMinutes({blueprintName, minMins});
            if (!response) {
                message.error("Please try again, we could complete your request.");
                return response;
            }
            message.success("Updated threshold value for "+blueprintName);
            dispatch(updateMinimumMinutesState({blueprintName, minMins}));
        } catch(err) {
            console.log(err);
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}