import { Result, Button, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { logout } from "../store/actions/signin.action";
import { useDispatch } from "react-redux";

const Page403 = () => {
    const navigate = useNavigate();
    const {
        token: { colorBgLayout },
    } = theme.useToken();
    const dispatch = useDispatch();

    return (
        <Result
            style={{ backgroundColor: colorBgLayout, height: "100vh" }}
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
                <Button
                    onClick={() => {
                        dispatch(logout());
                        // navigate({ pathname: "/auth/login" });
                    }}
                    type="primary"
                >
                    Back to Login
                </Button>
            }
        />
    );
};

export default Page403;
