import ActionType from '../action.types';

/*
    Reducer function to push data to the store in case of different signin action types
*/

export default function report(state = {offline: {chartData: {}, finalData: []}}, action) {
    switch (action.type) {
        case ActionType.OFFLINE_DEVICES:
            return {...state, offline: action.offline};
        default:
            return state;
    }
}
