import ActionType from '../action.types';

/*
    Reducer function to push data to the store in case of different minminutes action types
*/

export default function minminutes(state = {minminutes: []}, action) {
    switch (action.type) {
        case ActionType.SAVE_MINMINUTES:
            return {...state, minminutes: action.minminutes };
        default:
            return state;
    }
}
