import { ArrowLeftOutlined } from "@ant-design/icons";
import { Row, Form, Col, Input, Button} from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, Link, useParams, Navigate } from "react-router-dom";
import { resetPassword } from "../../store/actions/signin.action";

const ResetPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const token = params.token;

    const handleResetPassword = async (values) => {
        values.token = token;
        let flag = await dispatch(resetPassword(values));
        if (flag) {
            navigate("/auth/login");
        }
    }

    return (
        <>
            {!token && <Navigate to={"/auth/login"} />}
            <Row justify={"center"} align="middle">
                <h1>Reset Password</h1>
            </Row>
            <Form
                layout="vertical"
                onFinish={handleResetPassword}
            >
                <Form.Item
                    label={<b>Password</b>}
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label={<b>Confirm Password</b>}
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Col flex={"100%"}>
                    <Form.Item>
                        <Button block type="primary" htmlType="submit">
                            Reset Password
                        </Button>
                    </Form.Item>
                </Col>
            </Form>
            <Col>
                <Row justify={"center"} style={{textAlign: 'center'}}>
                    <b>
                        <Link to={"/auth/login"}> <ArrowLeftOutlined/>  &nbsp; Back to Login </Link>
                    </b>
                </Row>
            </Col>
        </>
    );
}

export default ResetPassword;