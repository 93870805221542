import { Card, Col, Row, Spin, theme } from "antd";
import Logo from "../../images/Logo-voyce.png";
import Ellipse from "../../images/ellipse.png";
import EllipseThin from "../../images/thin-circle.png";
import "./auth.scss";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const Auth = ({}) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const token = useSelector((state) => state?.signin?.token);
    const spinning = useSelector((state) => state?.signin?.isLoading);

    return (
        <Spin tip="Loading..." size="large" spinning={spinning}>
            <Row
                style={{ backgroundColor: colorBgContainer }}
                justify={"center"}
                align="middle"
                className="auth-container"
            >
                {token && <Navigate to="/pages" />}
                <Col className="divider-cols image-col" flex={"50%"}>
                    <img
                        alt="Decorative circle"
                        className="ellipse-bck"
                        src={Ellipse}
                    />
                    <img
                        alt="Decorative circle"
                        className="ellipse-thin-bck"
                        src={EllipseThin}
                    />
                    <Row
                        style={{ height: "100%", color: "white" }}
                        justify={"center"}
                        align="middle"
                    >
                        <Col>
                            <p style={{ fontSize: 24 }}>
                                <b>
                                    <span style={{ fontSize: 40 }}>
                                        V O Y C E
                                    </span>{" "}
                                    &nbsp;&nbsp; <br />
                                    <span style={{ fontSize: 30 }}>M</span>obile
                                    &nbsp;{" "}
                                    <span style={{ fontSize: 30 }}>D</span>evice
                                    &nbsp;{" "}
                                    <span style={{ fontSize: 30 }}>M</span>
                                    anagement
                                </b>
                            </p>
                        </Col>

                        <Row className="notice-row">
                            <Col>
                                <h4>
                                    Having trouble logging in? <br />
                                    Access Login Troubleshooting Help.
                                </h4>
                                <p>
                                    Please call (866) 745 6525 to speak with
                                    customer support representative who can help
                                    with any issues. Access Login
                                    Troubleshooting Help.
                                </p>
                            </Col>
                        </Row>
                    </Row>
                </Col>
                <Col className="divider-cols" flex={"auto"}>
                    <Row justify={"center"} align={"middle"}>
                        <Col>
                            <Row
                                style={{ maxWidth: "520px" }}
                                justify={"center"}
                                align={"middle"}
                            >
                                <Col style={{ maxWidth: 100 }}>
                                    <img alt="Voyce" width={100} src={Logo} />
                                </Col>
                            </Row>
                            <br />
                            <Card bordered={false} className="auth-card">
                                <Outlet />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Spin>
    );
};

export default Auth;
