import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Page403 from "./403";
import jwtDecode from "jwt-decode";
import { Popconfirm, Popover } from "antd";

const ALLOWED_ROUTES = [
    '/pages/usage',
    '/pages/blueprint',
]

const ProtectedRoutes = () => {
    const token = useSelector((state) => state.signin?.token);
    const user = useSelector((state) => state.signin?.user);
    const location = useLocation();
    const [pathname, setPathname] = useState("");
    useEffect(() => {
        setPathname(location.pathname);
    }, [location.pathname]);

    const isAuthenticated = () => {
        if (!token) return false;
        let { exp } = jwtDecode(token);
        return !(Date.now() >= exp * 1000);
    } 

    const UnauthorizeUser = () => {
        console.log(user)
        return user?.permissions || (
            user.vdmsRoute && ALLOWED_ROUTES.find((route) => pathname.includes(route))
        )
    };

    return isAuthenticated() ? (
        !UnauthorizeUser() ? (
            <Page403 />
        ) : (
            <Outlet />            
        )
    ) : (
        <Navigate to={"/auth/login"} />
    );
};

export default ProtectedRoutes;
