import ActionType from '../action.types';

/*
    Reducer function to push data to the store in case of different device Info action types
*/

export default function deviceInfo(state = {
        totalDeviceDeployed: 0,
        totalInUseDevices: 0,
        totalUnsedDevices: 0,
        totalCustomers: 0,
        finalData: [],
        chartData: undefined,
        devicesByDate: []
    }, action) {
    switch (action.type) {
        case ActionType.DEVICE_INFO:
            return { 
                ...state,
                totalDeviceDeployed: action.totalDeviceDeployed,
                totalInUseDevices: action.totalInUseDevices,
                totalUnsedDevices: action.totalUnsedDevices,
                totalCustomers: action.totalCustomers,
                finalData: action.finalData,
                chartData: action.chartData
            };
        case ActionType.DEVICES_BY_DATE:
            return {...state, devicesByDate: action.devicesByDate};
        default:
            return state;
    }
}
