import { ExportToCsv } from "export-to-csv";

const exportAsCSV = (data, filename, headers, title) => {
  const options = {
    title: title,
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showTitle: true,
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    filename,
    headers,
  };
  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(data);
};

export default exportAsCSV;
