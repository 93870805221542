import Routes from './route';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBoundary from './boundary';
import { useEffect } from 'react';
import Service from './services';
import { logout } from './store/actions/signin.action';
import { ConfigProvider } from 'antd';
import { DarkTheme, LightTheme } from './theme';

function App() {

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  console.log("--------- MDM Store ---------\n", state);

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('unload', (event) => {
        event.preventDefault();
        dispatch(logout())
      });
    }, 1000);
  }, [])

  return (
    <ConfigProvider theme={state?.signin?.theme == 'dark' ? DarkTheme : LightTheme} >
      <ErrorBoundary>
        <Routes/>
      </ErrorBoundary>
    </ConfigProvider>
  );
}

export default App;
