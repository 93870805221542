import { Axios } from "axios";

class MinMinutesService {

    /**
     * MinMinutes Services
     * @param {Axios} request 
     */
    constructor(request) {
        this.request = request;
    }

    async getMinimumMinutes() {
        try {
            let response = await this.request.get('/minimum-minutes');
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async updateMinimumMinutes({minMins, blueprintName}) {
        try {
            let response = await this.request.post('/device-company-informations', {minMins, blueprintName});
            return response.data?.status;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

}

export default MinMinutesService;