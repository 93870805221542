import { ArrowLeftOutlined, BackwardFilled, WarningOutlined } from "@ant-design/icons";
import { Button, Col, Progress, Row, Table, Typography } from "antd"
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Service from "../../services";

const ExpandedRowRender = ({year, serialNumber}) => {
    const [data, setData] = useState([]);
    const isLoading = useSelector((state) => state?.signin?.isLoading);
    const location = useLocation();
    const defaultThreshold = 200;

    useEffect(() => {
        setData([])
        Service.DeviceInfo().getDeviceUsageMonth({year, serialNumber: serialNumber })
        .then((res) => setData(res));
    }, [year])

    const columns = [
        {
            title: 'Month',
            dataIndex: 'start',
            key: 'start',
            render: (text) => moment(text).format("MMMM")
        },
        {
            title: 'Actual Minutes Used',
            dataIndex: 'total',
            key: 'name',
            render: (total, row) => {
                if (moment(row?.start).isAfter(moment(), "months")) {
                    return <i>No data</i>
                }
                return total || 0
            },
            width: "20%"
        },
        {
            title: 'Met Threshold',
            dataIndex: 'name',
            key: 'name',
            render: (text, row) => {
                let total = row.total || 0;
                let threshold = (location.state?.minMins && location.state?.minMins != 0) ? 
                    location.state?.minMins : defaultThreshold;
                return <Progress 
                    status={
                        moment(row?.start).isSame(moment(), "months") ? "active" : 
                        (moment(row?.start).isBefore(moment(), "months") && threshold > total) ? "exception" :
                        (total > threshold) ? "success" : "normal"
                    }
                    percent={Math.floor((total/threshold)*100)}
                    style={{paddingRight: 5}}
                />
            },
            width: "25%"
        },
    ]

    return <Table loading={isLoading} columns={columns} dataSource={data} pagination={false} />;
}

const MonthTable = () => {
    const {serialNumber} = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const data = [
        {
            year: moment().subtract(1, "years").format("YYYY"), 
            key: 0,
            minMins: location?.state?.minMins ? 
                <b style={{color: "#25e94e"}} >{location?.state?.minMins}</b> : 
                <><WarningOutlined style={{color: "yellow"}} /> &nbsp; Please set threshold value</>
        },
        {   
            year: moment().format("YYYY"), 
            key: 1,
            minMins: location?.state?.minMins ? 
                <b style={{color: "#25e94e"}} >{location?.state?.minMins}</b> : 
                <><WarningOutlined style={{color: "yellow"}} /> &nbsp; Please set threshold value</>
        },
    ]

    const columns = [
        {
          title: 'Year',
          dataIndex: 'year',
          key: 'year',
        },
        {
          title: 'Minimum Minutes',
          dataIndex: 'minMins',
          key: 'key'
        },
    ]

    return (
        <> 
        <Row align={"middle"} gutter={15}>
            <Col>
                <Button icon={<ArrowLeftOutlined/>} onClick={() => navigate("/pages/usage")}>
                    Back
                </Button>
            </Col>
            <Col>
                <Typography.Title style={{margin: 0}} level={3} >
                    Usage
                </Typography.Title>
            </Col>
        </Row>
        <br/>
        <Table
            title={() => <>&nbsp; {location?.state?.deviceName}</>}
            footer={() => <i>&nbsp; <span style={{color: "yellow"}}>Note:</span> By default the value of Minimum Minutes is considered as 200.</i>}
            columns={columns}
            expandable={{
                expandedRowRender: (record) => <ExpandedRowRender serialNumber={serialNumber} {...record}/>,
                defaultExpandedRowKeys: [1],
            }}
            dataSource={data}
            size="middle"
            pagination={false}
        />
        </>
    )
}

export default MonthTable;