import { message, Row, Form, Col, Input, Button} from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { signup } from "../../store/actions/signin.action";

const SignUp = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCreateUser = async (userObject) => {
        let created = await dispatch(signup(userObject));
        if (!created) return;
        message.success("We sent you an activation mail to "+userObject.email+".", 3000);
        navigate("/auth/signin");
    }

    return (
        <>
            <Row justify={"center"} align="middle">
                <h1>Sign Up</h1>
            </Row>
            <Form
                layout="vertical"
                onFinish={handleCreateUser}
            >
                <Form.Item
                    label={<b>First Name</b>}
                    name="firstname"
                    rules={[{ required: true, message: 'Please input your first name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={<b>Last Name</b>}
                    name="lastname"
                    rules={[{ required: true, message: 'Please input your last name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={<b>E-mail</b>}
                    name="email"
                    rules={[{ required: true, type: "email", message: 'Please input valid email!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={<b>Password</b>}
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label={<b>Confirm Password</b>}
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Col flex={"100%"}>
                    <Form.Item>
                        <Button block type="primary" htmlType="submit">
                            Create an account
                        </Button>
                    </Form.Item>
                </Col>
                <h3>Already have an account? <Link to="/auth/login" > Sign in </Link></h3>
                <b><i>You'll need access to the above-mentioned email address to verify your account</i></b>
            </Form>
        </>
    );
}

export default SignUp;