import { message } from "antd";
import { Axios } from "axios";
import constants from "../constants";

class AuthService {
    /**
     * Authentication Services
     * @param {Axios} request
     */
    constructor(request) {
        this.request = request;
        this.request.defaults.baseURL = constants.AUTH_BASE_URL;
    }

    async login(email, password) {
        try {
            let response = await this.request.post("/users/login", {
                email,
                password,
            });
            console.log(response);
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async signup(userObj) {
        try {
            let response = await this.request.post("/users", userObj);
            console.log(response.data);
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async forgotPassword(email) {
        try {
            let response = await this.request.post("/forgot-password", {
                email,
                redirectUrl: constants.BASE_URL + "/reset-password",
            });
            console.log(response.data);
            return response.data?.success;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async resetPassword(values) {
        try {
            let response = await this.request.post("/reset-password", values);
            console.log(response.data);
            if (response.data && !response.data.success) {
                message.error(response.data.msg);
            }
            return response.data?.success;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async logout(sessionId) {
        try {
            let response = await this.request.delete("/sessions/" + sessionId);
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }

    async updateUserInfo(values) {
        try {
            let response = await this.request.post("/update-user-info", values);
            console.log("updateUserInfo: ", response.data);
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async updateS3Bucket(values) {
        try {
            const bucketName = constants.S3_BUCKETNAME;
            console.log("in update S3 Bucket: ", values, bucketName);
            let response = await this.request.post(
                `/buckets/${bucketName}/upload`,
                values,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            console.log("updateUserInfo: ", response.data);
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async validateSSOToken(accessToken) {
        try {
            let response = await this.request.post("/users/sso/login", {
                token: accessToken
            });
            console.log(response);
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
}

export default AuthService;
