import { Link, useNavigate} from "react-router-dom";
import SmartTable from "../../components/table";
import React, { useEffect, useState } from "react";

import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import "./usage.scss";
import { useSelector, useDispatch } from "react-redux";
import exportAsCSV from "../../services/exportToCVS.service";
import { fetchDevicesByDate } from "../../store/actions/device.action";
import Service from "../../services";
import { Row, Skeleton, Spin } from "antd";
import { SmallDashOutlined } from "@ant-design/icons";
import { GetColumnSearchProps } from "../../components/table/common";

const Usage = () => {
    const dispatch = useDispatch();
    const [usageData, setUsageData] = useState([]);
    const [range, setRange] = useState({
      minDate: new Date(),
      maxDate: new Date()
    })
    const defaultThreshold = 0

    const data = useSelector((state) => state.deviceInfo?.devicesByDate);
    const user = useSelector((state) => state.signin?.user);
    const selectedCompanies = useSelector((state) => state.signin?.selectedCompanies);

    useEffect(() => {
      dispatch(fetchDevicesByDate(
        undefined,
        user.ssoLogin ? {...user, selectedCompanies: selectedCompanies || [] } : undefined
      ));
      data && data.length > 0 && handleUsageData(range)
    }, [])

    const dateFilter = (dateRange) => {
      setRange(dateRange)
      data && data.length > 0 && handleUsageData(dateRange)
    };

    useEffect(() => {
      data && data.length > 0 && handleUsageData(range)
    }, [data])

    const handleUsageData = (dateRange) => {
      Service.DeviceInfo().getDeviceUsage({
        serialNumbers: data?.map((e) => e.serialNumber),
        ...dateRange
      }).then((res) => setUsageData(res))
    }

    const getActualMinutes = (row) => {
      if (!usageData || (usageData && !usageData?.find(e => e._id == row.serialNumber))) {
        return 0
      }
      return usageData?.find(e => e._id == row.serialNumber)?.total;
    }

    const getPercentage = (total, threshold=0) => Math.floor((total/threshold)*100)

    const columns = [
        {
          title: "Company Name",
          dataIndex: "blueprintName",
          sorter: (a, b) => a.blueprintName.localeCompare(b.blueprintName),
          ...GetColumnSearchProps({dataIndex: "blueprintName"})
        },
        {
          title: "Device Name",
          dataIndex: "deviceName",
          defaultSortOrder: "descend",
          sorter: (a, b) => a.deviceName?.localeCompare(b.deviceName),
          ...GetColumnSearchProps({dataIndex: "deviceName"}),
        },
        {
          title: "Serial Number",
          dataIndex: "serialNumber",
          sorter: (a, b) => a.serialNumber?.localeCompare(b.serialNumber),
          ...GetColumnSearchProps({dataIndex: "serialNumber"}),
        },
        {
          title: "Mac Address",
          dataIndex: "mac_address",
          ...GetColumnSearchProps({dataIndex: "mac_address"}),
        },
        {
          title: "Minimum Minutes",
          dataIndex: "minMins",
          render: (text) => {
            if (!text) {
              return <SmallDashOutlined />
            }
            return text
          }
        },
        {
          title: "Actual Minutes Used",
          dataIndex: "",
          sorter: (a, b) => {
            return getActualMinutes(a) - getActualMinutes(b)
          },
          render: (text, row) => {
            let actualMinutes = getActualMinutes(row);
            let threshold = (row.minMins && (row.minMins != 0)) ? row.minMins : defaultThreshold
            if (actualMinutes == 0) {
              return <SmallDashOutlined />
            }
            let percent = getPercentage(actualMinutes, threshold);
            let className = 
              percent == Infinity ? "text-white" : 
              (percent > 99 && percent < Infinity) ? "text-green" :
              (percent > 75 && percent < 99) ? "text-orange" :
              (percent < 75 && percent > 50) ? "text-yellow" :
              "text-red"
            return <div className={className} ><b>{actualMinutes}</b></div>
          }
        },
        {
          title: "Detail",
          render: (text, row) => <Link 
              to={`/pages/usage/${row.serialNumber}`}
              state={row}
              disabled={
                getActualMinutes(row) == 0
              } 
            >
              <b>view</b>
            </Link>
        }
      ];
    
      const downloadCSV = (range) => {
        let count = 0;
        let downloadArray = [];
        if (!data || data.length == 0) return alert("no data");
        else {
          for (let list of data) {
            count++;
            let newList = {
              "Index No.": count,
              "Compamy Name": list.blueprintName,
              "Device Name": list.deviceName,
              "Serial Number": list.serialNumber,
              "Mac Address": list.mac_address,
              "Minimum Minutes": list.minMins,
              "Actual Minutes Used": (!usageData || (usageData && !usageData?.find(e => e._id == list.serialNumber))) ? 
                "NA": usageData?.find(e => e._id == list.serialNumber)?.total
            };
            downloadArray.push(newList);
          }
          exportAsCSV(
            downloadArray,
            new Date().toISOString().split("T")[0],
            [
              "Index No.",
              "Compan Name",
              "Device Name",
              "Serial Number",
              "Mac Address",
              "Minimum Minutes",
              "Actual Minutes Used"
            ],
            "Device Information (Selected Range: " +
              moment(range.minDate).format("MMM DD YY").toString() +
              " - " +
              moment(range.maxDate).format("MMM DD YY").toString() +
              ")"
          );
        }
    };

    const downloadPDF = (range) => {
        let downLoadArray = [];
        let count = 0;
        for (let list of data) {
          if (list) {
            count++;
            let newArray = [
              count,
              list.blueprintName,
              list.deviceName,
              list.serialNumber,
              list.mac_address,
              list.minMins,
              (!usageData || (usageData && !usageData?.find(e => e._id == list.serialNumber))) ? 
                "NA": usageData?.find(e => e._id == list.serialNumber)?.total,
            ];
            downLoadArray.push(newArray);
          }
        }
    
        const headerList = [
          "Index",
          "Company Name",
          "Device Name",
          "Serial Number",
          "Mac Address",
          "Minimum Minutes",
          "Actual Minutes Used",
        ];

        const doc = new jsPDF("landscape", "px", "a4");
        doc.setFontSize(18);
        // doc.text(id + " Blueprint", 30, 20);
        doc.setFontSize(12);
        doc.text(
            "Selected range: " +
                moment(range.minDate).format("MMM DD YY").toString() +
                " - " +
                moment(range.maxDate).format("MMM DD YY").toString(),
            30,
            35
        );
        autoTable(doc, {
            startY: 50,
            head: [headerList],
            body: downLoadArray,
            headStyles: {
                fillColor: [220, 220, 220],
                textColor: [33, 33, 33],
            },
        });
        doc.save(new Date().toISOString().split("T")[0]);
    };
    return (
        <SmartTable
            downloadPDF={downloadPDF}
            downloadCSV={downloadCSV}
            columns={columns}
            title={"Usage"}
            data={data}
            dateFilter={dateFilter}
            hideBackBtn
            rangeType={"month"}
        />
    );
};

export default Usage;
