import ActionType from '../action.types';
import Service from '../../services';
import * as echarts from 'echarts/core';
import { saveMinimumMinutes } from './minminutes.action';

export function saveDeviceInformation(response) {
    let mdmData = response.response
    let totalDeviceDeployed = [],
        totalInUseDevices = [],
        totalUnsedDevices = [],
        totalCustomers = [],
        finalData = [],
        chartData = {},
        sortedFinalData = {};
    console.log('this is the gg accordion',mdmData)
    let valueOfEachkey = [];
    let graphics = echarts.graphic;
    for (let index = 0; index < mdmData.keys.length; index++) {
        const keyName = mdmData.keys[index]
        const element = mdmData.response[keyName];
        const obj = {name:keyName, body: element, total: element.length}
        console.log(keyName);
        if(keyName.toLowerCase().indexOf('initial_setup') > -1 && 
         keyName.toLowerCase().indexOf('initial_setup_v2') == -1
        ) {
            // totalDeviceDeployed += element.length
            totalDeviceDeployed = totalDeviceDeployed.concat(element);
        }
        if(keyName.toLowerCase().indexOf('hospital') > -1 || keyName.toLowerCase().indexOf('voyce') > -1 ){
            // totalInUseDevices += element.length
            totalInUseDevices = totalInUseDevices.concat(element);
        }
        finalData.push(obj);
        valueOfEachkey.push({value: element.length});
    }
    const specialKey = mdmData.specialKeys[0]
    console.log('I am the specifcal key',specialKey)
    if(mdmData.response[specialKey]){
        const obj = {name: specialKey, body: mdmData.response[specialKey] , total: mdmData.response[specialKey].length}
        finalData.push(obj)
    }
    
    totalUnsedDevices = mdmData.response[specialKey] ? mdmData.response[specialKey] : [];
    let v2_initial_devices = finalData.find((doc) => doc.name.toLowerCase().includes("initial_setup_v2"))?.body || []
    totalUnsedDevices = totalUnsedDevices.concat(v2_initial_devices)
    totalCustomers = mdmData?.keys?.filter((data) => !(data.toLowerCase().includes("initial_setup")))
    finalData?.sort((p1, p2) => 
    (p1.total < p2.total) ? 1 : (p1.total > p2.total) ? -1 : 0)
    chartData = {legends: finalData.map((data)=> {
        return data?.name
    }),
    seriesData: finalData.map((data)=> {
        return data?.total
    })
}
    return {
        type: ActionType.DEVICE_INFO,
        totalDeviceDeployed,
        totalInUseDevices,
        totalUnsedDevices,
        totalCustomers,
        finalData,
        chartData
    };
}

export function saveDevicesByDate(response) {
    let mdmData = response.response
    let devices = [];

    for (let index = 0; index < mdmData.keys.length; index++) {
        const keyName = mdmData.keys[index]
        const elements = mdmData.response[keyName];
        devices = devices.concat(elements);
    }

    devices.forEach(element => {
        element.mac_address = element?.details?.network?.mac_address
    });

    return {
        type: ActionType.DEVICES_BY_DATE,
        devicesByDate: devices
    };
}

export function getDeviceInfo() {
    return async (dispatch) => {
        try {
            let devices = await Service.DeviceInfo().getDeviceInfo();
            dispatch(saveMinimumMinutes(devices.response));
            dispatch(saveDeviceInformation(devices));
        } catch(err) {
            console.log(err);
        }
    };
}

export function fetchDevicesByDate(date, requestBody) {
    return async (dispatch) => {
        try {
            let devices = await Service.DeviceInfo().getDeviceInfo(date, requestBody);
            dispatch(saveDevicesByDate(devices));
        } catch(err) {
            console.log(err);
        }
    };
}
