import "./blueprint.scss";
import React, { useState, useEffect } from "react";
import { Card, Row, Col, Input, Typography, theme, Divider, Spin } from "antd";
import { FileFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceInfo } from "../../store/actions/device.action";

const { Search } = Input;

const Blueprint = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [searchText, setText] = useState("");

    // from redux
    const data = useSelector((state) => JSON.parse(JSON.stringify(state?.deviceInfo?.finalData)));
    const isLoading = useSelector((state) => state?.signin?.isLoading)
    const dispatch = useDispatch();
    useEffect(() => {
        if (!data || data?.length == 0) {
            dispatch(getDeviceInfo());
        }
    }, []);

    const renderCards = () => {
        data.sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );
        return data
            .filter((blueprint) => {
                if (searchText && searchText.trim() !== "") {
                    return blueprint?.name
                        ?.toLowerCase()
                        ?.includes(searchText.toLowerCase());
                }
                return true;
            })
            .map((blueprint) => {
                return (
                    <Col key={blueprint?.name} span={5} className="card-div">
                        <Link to={`/pages/blueprint/${blueprint?.name}`}>
                            <Card
                                hoverable
                                bordered={false}
                                className="card-board"
                                cover={
                                    <div className="icon-div">
                                        <FileFilled className="card-icon" />
                                    </div>
                                }
                            >
                                <div className="blueprint-text">
                                    {blueprint?.name}
                                </div>
                                <div className="device-text">
                                    Devices: {blueprint?.total}
                                </div>
                            </Card>
                        </Link>
                    </Col>
                );
            });
    };

    return (
        <Spin spinning={isLoading} tip="Cooking up your data...">
            <div
                className="site-card-wrapper"
                style={{ backgroundColor: colorBgContainer }}
            >
                <Row style={{ padding: "0px 16px" }} align={"middle"}>
                    {/* <span className="heading">Blueprint</span> */}
                    <Typography.Title level={3}>Blueprint</Typography.Title>
                    <Search
                        className="search-div"
                        placeholder="Input blueprint name"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={(text) => setText(text)}
                    />
                </Row>
                <Divider />
                <Row
                    gutter={[16]}
                    justify="space-between"
                    className="cards-div"
                >
                    {renderCards()}
                </Row>
            </div>
        </Spin>
    );
};

export default Blueprint;
