import { Card, Col, ConfigProvider, Image, Layout, Row, Spin, Typography, message, theme } from "antd";
import Logo from '../images/logo.png';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ssoLogin } from "../store/actions/signin.action";

const ALLOWED_ROUTES = [
    '/usage',
    '/blueprint',
]

const SSORedirect = (props) => {
    const location = useLocation();
    const disptach = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        let token = new URLSearchParams(location.search).get("token");
        if (token) {
            disptach(ssoLogin(decodeURIComponent(token)))
            .then((user) => {
                setTimeout(() => {
                    if (ALLOWED_ROUTES.includes(user.vdmsRoute)) {
                        navigate('/pages' + user.vdmsRoute)
                    } else {
                        message.error("You don't have access to the page requested")
                    }
                }, 2000)
            })
        }
    }, [])

    return (
        <ConfigProvider theme={{algorithm: theme.darkAlgorithm}} >
        <Layout>
        <Row justify={"center"} align={"middle"} style={{height: '100vh'}}>
            <Col>
                <Card title={
                        <Row align={"middle"} gutter={20}>
                            <Col>
                                <Image height={32} width={32} src={Logo} preview={false} />
                            </Col>
                            <Col style={{fontSize: 32}}>
                                V O Y C E
                            </Col>
                            <Col style={{marginLeft: 'auto'}}>
                                <Spin spinning />
                            </Col>
                        </Row>
                        
                    }>
                    <Row align={"middle"} gutter={10}>
                        <Col>
                            <Typography.Title>
                            You are being redirected to view your requested report.
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Typography.Paragraph>
                    We are processing your request and redirecting you to the appropriate report. Please be patient while we complete the necessary steps.
                    </Typography.Paragraph>
                </Card>
            </Col>
        </Row>
        </Layout>
        </ConfigProvider>
    )
}

export default SSORedirect;
