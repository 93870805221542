import { theme } from "antd";

export const DarkTheme = {
    algorithm: theme.darkAlgorithm,
    token: {
        // colorPrimary: "#ff3b3b",
        // colorBgContainer: "#28293c",
        // colorBgLayout: "#1c1c27"
        alertColor: 'yellow',
        lineChartColor: 'yellow'
    }
}

export const LightTheme = {
    algorithm: theme.defaultAlgorithm,
    token: {
        alertColor: 'tomato',
        lineChartColor: 'tomato'
    }
}