import axios from 'axios';
import { store } from '..';
import constants from '../constants';
import { operationInProgress } from '../store/actions/signin.action';

const request = () => {

    // Setting default options for VDMS
    const defaultOptions = {
        // baseURL: constants.BASE_URL,
        baseURL: constants.API_BASE_URL,   
        headers: {
          'Content-Type': 'application/json',
        },
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for request
    instance.interceptors.request.use(function (config) {
        const token = sessionStorage.getItem('token');
        config.headers.Authorization =  token ? `Bearer ${token}` : '';
        /** TODO - ADD LOADER CODE HERE */
        store.dispatch(operationInProgress(true));
        return config;
    }, function(error) {
        console.log('Error');
        return Promise.reject(error);
    });

    instance.interceptors.response.use(function(response) {
        /** TODO - ADD LOADER CODE HERE */
        store.dispatch(operationInProgress(false));
        return response;
    }, function(error) {
        /** TODO - ADD LOADER CODE HERE */
        store.dispatch(operationInProgress(false));
        console.log('Error fetching the data');
        return Promise.reject(error);
    });

    return instance;
};

export default request();