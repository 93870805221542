import { Axios } from "axios";
import constants from "../constants";
import axios from "./axios";

class DeviceInfoService {

    /**
     * Device Information Services
     * @param {Axios} request 
     */
    constructor(request) {
        this.request = request;
        this.request.defaults.baseURL = constants.API_BASE_URL;
    }

    async getDeviceInfo(data, body={}) {
        try {
            let path = !data ? '/device-informations/undefined/undefined' : '/device-informations/'+data.minDate+"/"+data.maxDate;
            let response = await this.request.post(path, body);
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getDeviceUsage(data={
        serialNumbers: null,
        minDate: new Date(),
        maxDate: new Date()
    }) {
        try {
            let response = await this.request.post(
                '/device-informations/usage/by-date',
                data
            );
            console.log(response.data)
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getDeviceUsageMonth(data={
        serialNumber: null,
        year: "2022"
    }) {
        try {
            let response = await this.request.post(
                '/device-informations/usage/by-month',
                data
            );
            console.log(response.data)
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    async getOfflineDevices() {
        try {
            let response = await this.request.get(
                '/device-informations/offline/chart'
            );
            console.log(response.data)
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }
    
}

export default DeviceInfoService;