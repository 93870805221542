import { useDispatch } from "react-redux";
import { forgotPassword } from "../../store/actions/signin.action";
import { Row, Form, Col, Input, Button} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onFinish = async (values) => {
        let flag = dispatch(forgotPassword(values));
        if (flag) {
            navigate("/auth/login");
        }
    };

    return(
        <>
            <Row justify={"center"} align="middle">
                <h1>Forgot Password</h1>
            </Row>
            <Form
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    label={<b>Email</b>}
                    name="email"
                    rules={[{ required: true, type: 'email', message: 'Please input valid email!' }]}
                >
                    <Input />
                </Form.Item>
                <Col flex={"100%"}>
                    <Form.Item>
                        <Button block type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
            </Form>
            
            <Col>
            <p>Enter yout email and we'll send you a link to reset your password.</p>
                <Row justify={"center"} style={{textAlign: 'center'}}>
                    <b>
                        <Link to={"/auth/login"}> <ArrowLeftOutlined/>  &nbsp; Back to Login </Link>
                    </b>
                </Row>
            </Col>
        </>
    );
}

export default ForgotPassword;