import { Row, Form, Col, Input, Button, Divider} from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { signin } from "../../store/actions/signin.action";

const SignIn = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
            <Row justify={"center"} align="middle">
                <h1>Sign In</h1>
            </Row>
            <Form
                layout="vertical"
                onFinish={(values) => dispatch(signin(values))}
            >
                <Form.Item
                    label={<b>Email</b>}
                    name="email"
                    rules={[{ required: true, type: 'email', message: 'Please input valid email!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={<b>Password</b>}
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Col flex={"100%"}>
                    <Form.Item>
                        <Button block type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
            </Form>
            
            <Col>
                <Row>
                    <b>
                        <Link to={"/auth/forgot-password"}>Forgot your password?</Link>
                    </b>
                </Row>
            </Col>

            <Divider/>

            <h4>Don't have an account?</h4>
            <p >
                If you don't have an account already, sign up here:
            </p>
            <Button block type="primary" onClick={() => navigate('/auth/signup')}>
                Create an account
            </Button>
        </>
    );
}

export default SignIn;