import React, { useState } from "react";
import {
    message,
    Row,
    Col,
    theme,
    Table,
    Divider,
    Form,
    Select,
    InputNumber,
    Button,
    Typography,
    Input,
    Space,
    Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "./account.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../../store/actions/signin.action";
import { useNavigate } from "react-router-dom";
import Service from "../../services/index";

const Account = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [form] = Form.useForm();
    const [password, setPassword] = useState("");
    const [passwordEnable, setPasswordEnable] = useState(false);
    const navigate = useNavigate();

    const user = useSelector((state) => state.signin.user);
    console.log("user: ", user);
    const fullName = user?.name?.split(" ");
    const [fileList, setFileList] = useState([]);

    const handleChange = ({ fileList: newFileList }) => {
        if (
            newFileList.length > 0 &&
            newFileList[newFileList.length - 1].uid &&
            newFileList[newFileList.length - 1].type.startsWith("image/")
        ) {
            setFileList([newFileList[newFileList.length - 1]]);
            message.success(
                `${
                    newFileList[newFileList.length - 1].name
                } file uploaded successfully.`
            );
        } else if (
            newFileList.length > 0 &&
            !newFileList[newFileList.length - 1].type.startsWith("image/")
        ) {
            message.error("Only image files are allowed.");
            setFileList([]);
        }
    };

    const handleRemove = (file) => {
        setFileList([]);
    };

    const handleBackButton = () => {
        navigate("/pages");
    };

    const handleSetPasswordEnable = () => {
        setPasswordEnable(!passwordEnable);
    };
    const dispatch = useDispatch();
    const handleUpdateUser = async (userObject) => {
        // use file obj --> call upload api s3
        // return a location --> get url from s3 response
        // use url --> send to backend with email pair
        userObject.email = user?.email;
        console.log("ususerObjecter: ", userObject);
        if (userObject.firstname === undefined) {
            userObject.firstname = fullName[0];
        }
        if (userObject.lastname === undefined) {
            userObject.lastname = fullName[fullName.length - 1];
        }
        if (userObject.profileImg?.file !== undefined) {
            // send file obj, get response
            if (!userObject.profileImg.file) {
                console.error("userObject.profileImg.file is empty");
            }
            const bodyFormData = new FormData();
            bodyFormData.append(
                "image",
                userObject.profileImg.file
                // userObject.profileImg.file.name
            );
            console.log("bodyFormData: ", bodyFormData);
            try {
                const response = await Service.Auth().updateS3Bucket(
                    bodyFormData
                );
                console.log("Response: ", response[0].Location);
                userObject.imgURL = response[0].Location;
            } catch (err) {
                console.error(err);
                message.error(
                    "Something went wrong while updating the S3 bucket."
                );
            }
        }
        let updated = await dispatch(updateUserInfo(userObject));
        // message.success(
        //     "We have updated user: " + user.email + " information."
        // );
    };

    return (
        <div
            style={{
                backgroundColor: colorBgContainer,
                padding: 20,
                borderRadius: 10,
            }}
        >
            <Typography.Title level={3}>Manage Account</Typography.Title>
            <Divider />
            <Form
                className="form-div"
                form={form}
                layout="vertical"
                onFinish={handleUpdateUser}
            >
                <h4 className="div-title">User Information</h4>
                <Col span={12}>
                    <Form.Item label={<b>First Name</b>} name="firstname">
                        <Input
                            defaultValue={user?.name?.split(" ")[0]}
                            size="large"
                        />
                        {/* <Input defaultValue="123123123" size="large" /> */}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={<b>Last Name</b>} name="lastname">
                        <Input
                            defaultValue={
                                user?.name?.split(" ")[
                                    user?.name?.split(" ").length - 1
                                ]
                            }
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={<b>E-mail</b>} name="email">
                        <Input
                            disabled
                            defaultValue={user?.email}
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Form.Item label={<b>Profile Image</b>} name="profileImg">
                    <Upload
                        fileList={fileList}
                        onChange={handleChange}
                        onRemove={handleRemove}
                        beforeUpload={() => false}
                    >
                        <Button size="large">
                            <UploadOutlined />
                            Upload
                        </Button>
                    </Upload>
                </Form.Item>
                <Divider />

                <div className="reset-div">
                    <h4 className="div-title">Reset Password</h4>
                    {!passwordEnable && (
                        <span
                            className="edit-div"
                            onClick={handleSetPasswordEnable}
                        >
                            Edit
                        </span>
                    )}
                    {passwordEnable && (
                        <span
                            className="edit-div"
                            onClick={handleSetPasswordEnable}
                        >
                            Cancel
                        </span>
                    )}
                </div>

                <Col span={12}>
                    <Form.Item label={<b>Old Password</b>} name="oldpassword">
                        <Input.Password
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={!passwordEnable}
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={<b>New Password</b>}
                        name="password"
                        rules={[
                            {
                                required: Boolean(password),
                                message: "Please enter new password!",
                            },
                        ]}
                    >
                        <Input.Password
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={!passwordEnable}
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="confirm"
                        label={<b>Confirm New Password</b>}
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                            {
                                required: Boolean(password),
                                message: "Please confirm your password!",
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue("password") === value
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(
                                            "The two passwords that you entered do not match!"
                                        )
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            disabled={!passwordEnable}
                            size="large"
                        />
                    </Form.Item>
                </Col>

                <br />
                <Row gutter={48} className="button-div">
                    <Col span={3}>
                        <Form.Item>
                            <Button
                                className="bot-btn"
                                block
                                onClick={handleBackButton}
                                size="large"
                            >
                                Back
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item>
                            <Button
                                className="bot-btn"
                                block
                                type="primary"
                                htmlType="submit"
                                size="large"
                            >
                                Update
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default Account;
