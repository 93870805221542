import {
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {
    Avatar,
    Button,
    Col,
    Dropdown,
    Layout,
    message,
    Row,
    Switch,
    theme,
    Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changeTheme, logout } from "../../store/actions/signin.action";
import dark from "../../images/dark.svg";
import light from "../../images/light.svg";
import React from "react";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;

const MDMHeader = ({ collapsed, setCollapsed, isSSOLogin }) => {
    const dispatch = useDispatch();
    const currentTheme = useSelector((state) => state.signin?.theme);
    const user = useSelector((state) => state.signin.user);
    const navigate = useNavigate();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const handleThemeChange = () => {
        dispatch(changeTheme(currentTheme == "light" ? "dark" : "light"));
    };

    const handleMenuClick = ({ key }) => {
        switch (key) {
            case "logout":
                dispatch(logout());
                break;
            case "manageAccount":
                navigate("/pages/account");
            default:
                return;
        }
    };

    const items = [
        {
            label: "Manage Account",
            key: "manageAccount",
            icon: <UserOutlined />,
        },
        {
            label: "Logout",
            key: "logout",
            icon: <LogoutOutlined />,
            danger: true,
        },
    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <Header
            hasSider
            style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                boxShadow: "0 1px 5px -3px #000",
                padding: 0,
                background: colorBgContainer,
                width: "100%",
            }}
            className="header"
        >
            <Row style={{ marginLeft: 20 }}>
                <Col hidden={isSSOLogin} style={{ fontSize: 20 }}>
                    {React.createElement(
                        collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                        {
                            className: "trigger",
                            onClick: () => setCollapsed(!collapsed),
                        }
                    )}
                </Col>
                <Col hidden={!isSSOLogin} style={{ fontSize: 20 }}>
                &nbsp;&nbsp;<Avatar
                        size="large"
                        icon={ <UserOutlined />}
                    />{" "} &nbsp;{user?.name}
                </Col>
                <Col style={{ marginLeft: "auto", marginRight: 20 }}>
                    <Switch
                        onChange={handleThemeChange}
                        style={{ width: 110, padding: 1 }}
                        checked={currentTheme == "light"}
                        unCheckedChildren={
                            <Row height={18} align={"middle"}>
                                <img src={dark} />
                                &nbsp;{" "}
                                <span style={{ fontSize: 8 }}>NIGHT MODE</span>
                            </Row>
                        }
                        checkedChildren={
                            <Row align={"middle"}>
                                <img height={18} src={light} />
                                &nbsp;{" "}
                                <span style={{ fontSize: 8 }}>DAY MODE</span>
                            </Row>
                        }
                    />
                </Col>
                <Col hidden={isSSOLogin} style={{ marginRight: 20 }}>
                    <Dropdown
                        menu={menuProps}
                        trigger={["click"]}
                        placement="bottomRight"
                    >
                        <div
                            onClick={(e) => e.preventDefault()}
                            style={{ cursor: "pointer" }}
                        >
                            <Avatar
                                size="large"
                                icon={
                                    user?.imgURL ? (
                                        <img src={user?.imgURL} />
                                    ) : user?.profileImg ? (
                                        <img src={user?.profileImg} />
                                    ) : (
                                        <UserOutlined />
                                    )
                                }
                            />{" "}
                            &nbsp; {user?.name} &nbsp;
                        </div>
                    </Dropdown>
                </Col>
                <Col hidden={!isSSOLogin} style={{ marginRight: 36 }}>
                    <Button onClick={() => dispatch(logout())}>Login</Button>
                </Col>
            </Row>
        </Header>
    );
};

export default MDMHeader;
