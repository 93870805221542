import { Navigate, Outlet } from "react-router-dom";
import { Layout, theme } from "antd";
import { SideMenu, Header } from "../../components/layout";
import './layout.scss';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { changeTheme } from "../../store/actions/signin.action";

const { Content, Footer } = Layout;

const Main = () => {

  const [collapsed, setCollapsed] = useState(false);
  const user = useSelector((state) => state.signin?.user);
  const dispatch = useDispatch();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const isSSOLogin = user?.ssoLogin;

  // const isAuthenticated = useSelector((state) => {
  //   if (!state?.signin?.token) return false;
  //   let { exp } = jwtDecode(state?.signin?.token);
  //   return !(Date.now() >= exp * 1000);
  // })

  useEffect(() => {
    dispatch(changeTheme('dark'))
  }, [])

  // console.log('isAuthenticated -----------> ', isAuthenticated);

  return (
    <>
      <Layout
        hasSider
        style={{
          minHeight: "100vh",
        }}
      >
        {!isSSOLogin && <SideMenu {...{collapsed, setCollapsed}}/>}
        <Layout className="site-layout">
          <Header isSSOLogin={isSSOLogin} {...{collapsed, setCollapsed}}/>
          <Content className="content-container" >
            <div className="inner-container" >
              <Outlet />
            </div>
          </Content>
          <Footer hasSider style={{ textAlign: "center" }} >
            Voyce ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </>
  );
};
export default Main;
