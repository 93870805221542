import { Route, Routes, Navigate } from "react-router-dom";
import Page404 from "./pages/404";
import Home from "./pages/home";
import Blueprint from "./pages/blueprint";
import Main from "./pages/layout";
import SmartTable from "./components/table";
import Signin from "./pages/auth/signin";
import Auth from "./pages/auth";
import ForgotPassword from "./pages/auth/forgot.password";
import ResetPassword from "./pages/auth/reset.password";
import Threshold from "./pages/threshold";
import SignUp from "./pages/auth/signup";
import Usage from "./pages/usage";
import BlueprintTable from "./pages/blueprint/blueprint.table";
import HomeTable from "./pages/home/home.table";
import MonthTable from "./pages/usage/month.table";

import OfflineDevices from "./pages/reports/offline.device";
import Account from "./pages/account";
import SSORedirect from "./pages/sso.redirect";
import ProtectedRoutes from "./pages/protected.route";

const Router = () => {
  return (
    <>
      <Routes>

        <Route path="/auth" element={<Auth/>}>
          <Route path={"signup"} element={<SignUp/>} ></Route>
          <Route path={"login"} element={<Signin/>} ></Route>
          <Route path={"forgot-password"} element={<ForgotPassword/>} ></Route>
          <Route path={"reset-password/:token"} element={<ResetPassword/>} ></Route>
          <Route index path="*" element={<Navigate to="/auth/login"/>} ></Route>
        </Route>

        <Route path="/pages" element={<ProtectedRoutes />}>
              {/**
               * Add all protected routes here.
               * (Ones which need authentication.)
               */}
          <Route path="/pages" element={<Main />}>
            <Route index element={<Home />} />
            <Route path="dashboard/:id" element={<HomeTable />} />
            <Route path="blueprint" element={<Blueprint />} />
            <Route path="blueprint/:id" element={<BlueprintTable/>} />
            <Route path="usage" element={<Usage/>} />
            <Route path="usage/:serialNumber" element={<MonthTable/>} />
            <Route path="threshold" element={<Threshold/>} />
            <Route path="reports">
              <Route index path="offline-devices" element={<OfflineDevices/>} />
            </Route>
            <Route path="account" element={<Account />} />
            {/* 
              Add Route here for diffrent pages that needs to be rendered
              inside the dashboard layout.
              eg. <Route path="/device-usage" element={<DeviceUsagePage/>} /> 
            */}
          </Route>
        </Route>

        <Route path="/sso/redirect" element={<SSORedirect/>} />

        {/* Wildcard path should always be the last route defined, to avoid redirections */}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </>
    );
};

export default Router;
